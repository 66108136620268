import {Controller} from "stimulus";
import Chart from 'chart.js';

export default class extends Controller {
  static targets = ["day", "graph", "graphContainer", "tab", "wrapper", "total", "label"];

  initialize() {
    Chart.defaults.global.animation.duration = 0;
    //this.setGraphWidth();

    this.start = parseInt(this.data.get('start'));
    this.end = parseInt(this.data.get('end'));
    this.interval = parseInt(this.data.get('interval'));
    this.renderGraph('active', 'Clients Engaged')
  }

  setGraphWidth() {
    this.graphContainerTarget.style.width = this.wrapperTarget.offsetWidth + 'px';
  }

  showGraph(event) {
    event.preventDefault();
    let active = event.currentTarget;
    this.tabTargets.forEach((tab, index) => {
      tab.classList.toggle("active", tab == active);
    });
    this.renderGraph(active.dataset.graph, active.dataset.label);
  }

  renderGraph(graph, label) {
    this.stat = graph;
    this.labelTarget.innerText = label;
    this.totalTarget.innerText = this.total;

    this.chart = new Chart(this.graphTarget, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [{
          label: label,
          data: this.points,
          fill: true,
          backgroundColor: "rgba(128,211,108, 0.6)",
          borderColor: "#59B247",
          borderWidth: 1,
          lineTension: 0
        }]
      },
      options: {}
    });
  }

  setDate(event) {
    let url = window.location.href.replace(location.search, '');
    window.location.href = url + '?range=' + event.currentTarget.value;
  }

  get points() {
    let day0 = this.dayTargets[0].dataset.date;
    let date_bucket = this.start;
    let points = [];
    while(date_bucket < day0) {
      console.log('looping in zero');
      points.push(0);
      date_bucket += this.interval;
      if (date_bucket >= this.end)  {
        break;
      }
    }
    this.dayTargets.forEach((day, index) => {
      points.push(day.dataset[this.stat]);
    });
    return points;
  }

  get total() {
    let total = 0;
    this.dayTargets.forEach((day, index) => {
      total += parseInt(day.dataset[this.stat]);
    });
    return total;
  }

  get labels() {
    return JSON.parse(this.data.get('labels'));
  }
}