import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["left", "right"];

  toggle(event) {
    let button = event.currentTarget;
    if (button.dataset.target === 'right') {
      this.rightTarget.classList.toggle('open');
    }
    else {
      this.leftTarget.classList.toggle('open');
    }
  }
}