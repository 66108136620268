import {Controller} from "stimulus";

export default class extends Controller {
  bindReplaceElement(event) {
    this.replace(event.detail[2].response);
  }

  replace(content) {
    this.element.outerHTML = content;
  }
}