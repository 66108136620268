import {Controller} from "stimulus";
import Chart from 'chart.js';

export default class extends Controller {
  static targets = ["point", "canvas"];

  initialize() {
    Chart.defaults.global.animation.duration = 0;
    if (this.hasCanvasTarget) {
      this.canvas = this.canvasTarget;
    }
    else {
      this.canvas = this.drawCanvas();
    }
    this.renderGraph();
  }


  drawCanvas() {
    let wrapper = document.createElement("DIV");
    wrapper.style.width = this.wrapperSize;
    wrapper.style.height = this.wrapperSize;
    let canvas = document.createElement("CANVAS");
    canvas.style.width = '100%';
    canvas.style.height = this.wrapperSize;
    wrapper.appendChild(canvas);
    this.element.appendChild(wrapper);
    return canvas;
  }

  renderGraph() {
    this.chart = new Chart(this.canvas, {
      type: this.data.get('type'),
      data: {
        labels: this.labels,
        datasets: [{
          data: this.points,
          backgroundColor: this.colors
        }]
      },
      options: {
        legend: {
          display: false
        }
      }
    });
  }

  get wrapperSize() {
    if (this.data.get('max')) {
      let max = parseInt(this.data.get('max'));
      if (this.element.offsetWidth > max) {
        return max + 'px';
      }
    }
    return this.element.offsetWidth + 'px';
  }

  get points() {
    let points = [];
    this.pointTargets.forEach((point, index) => {
      points.push(point.dataset.count);
    });
    return points;
  }

  get labels() {
    let labels = [];
    this.pointTargets.forEach((point, index) => {
      labels.push(point.dataset.label);
    });
    return labels;
  }

  get colors() {
    if (this.data.get('palette') == 'green') {
      return ["rgb(100,190,71)", "rgb(125,214,100)"];
    }
    return ["#0F4E8E", "#1068AD", "#469FD8", "#9BD0F1"];
  }
}