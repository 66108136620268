import Dropzone from "dropzone";
import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["button", "uploader", "dropzone", "startButton", "preview", "errorMessage"];

  initialize() {
    this.started = false;
    window.addEventListener('dragenter', event => {
      this.showDropzone();
    });
    window.addEventListener('dragleave', event => {
      this.dragLeave(event);
    });
  }

  connect() {
    this.dropZone = new Dropzone('form#dropzone', {
      url: this.url,
      headers: this.headers,
      previewTemplate: this.preview,
      dictDefaultMessage: 'Drop file here or click to upload',
      uploadProgress: this.uploadProgress,
      maxFiles: 1,
      maxFilesize: 256,
      acceptedFiles: '.csv',
      addRemoveLinks: false,
      autoProcessQueue: false
    });
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  showDropzone() {
    this.uploaderTarget.style.display = 'block';
  }

  startUpload() {
    this.dropZone.processQueue();
  }

  close() {
    this.dropZone.removeAllFiles();
    this.hideError();
    this.uploaderTarget.style.display = 'none';
  }

  dragLeave(event) {
    if (this.started) {
      return true;
    }
    if (event.clientX == 0) {
      this.close();
    }
  }

  fileAdded(file) {
    console.log(file);
    this.hideError();
    this.started = true;
    this.startButtonTarget.classList.toggle('disabled', false);
    this.startButtonTarget.disabled = false;
    this.checkAccepted(file);
  }

  checkAccepted(file) {
    if (file.accepted == undefined) {
      Promise.resolve().then(() => {
        this.checkAccepted(file);
      });
      return;
    }
    if (!file.accepted) {
      this.showError('Please upload a CSV file');
      this.dropZone.removeAllFiles();
    }

  }

  fileRemoved() {
    this.started = false;
    this.startButtonTarget.classList.toggle('disabled', true);
    this.startButtonTarget.disabled = true;
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      this.fileAdded(file);
    });

    this.dropZone.on("removedfile", file => {
      this.fileRemoved();
    });

    this.dropZone.on("maxfilesexceeded", file => {
      this.replaceFile(file);
    });

    this.dropZone.on("success", (file, responseText) => {
      window.location.href = responseText;
    });

    this.dropZone.on("error", (file, responseText) => {
      this.showError(responseText);
    });

  }

  replaceFile(file) {
    this.showError('Only one file can be uploaded at a time');
    this.dropZone.removeAllFiles();
    this.dropZone.addFile(file);
  }

  showError(error) {
    this.errorMessageTarget.innerHTML = error;
    this.errorMessageTarget.style.display = 'block';
  }

  hideError() {
    this.errorMessageTarget.innerHTML = '';
    this.errorMessageTarget.style.display = 'none';
  }

  uploadProgress(file, progress, bytesSent) {
    let progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
    progressElement.style.width = progress + "%";
    progressElement.textContent = progress + "%";
  }

  get preview() {
    return this.previewTarget.innerHTML;
  }

  get headers() {
    return {"X-CSRF-Token": document.head.querySelector("[name=csrf-token]").content};
  }

  get url() {
    return this.data.get('url');
  }
}